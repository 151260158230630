export default {
  address: {
    contactNumberLength: 0,
    contactNumberMinLength: 0,
    emailRequired: true,
    hidePin: true,
    hideCountry: true,
    fixCountry: '',
    pinCodeRequired: false,
    pinCodeLength: 0,
    pinCodeDigitOnly: false,
  },
  app: 'pru-rewards',
  appName: 'PRURewards',
  applyBgColorFromDjango: false,
  allowInsufficientPoint: true,
  registerNewAccount: false,
  allowPayment: true,
  contactInfo: {},
  defaultCurrency: 'SGD',
  defaultLanguage: 'en',
  defaultNationality: { name: 'Singapore', code: 'SG' },
  defaultUserIcon: 'assets/img/user.png',
  enableNomination: false,
  enforceDefaultLanguage: true,
  expiringPointQueryString: '&for=1',
  customRewardCategory: true,
  fallbackRewardIcon: 'assets/img/fallback-rewards.png',
  topMenu: true,
  freshchat: {
    isShow: true,
    pages: 'all',
    script: '//fw-cdn.com/11344154/4030384.js',
    chat: false,
    tags: ['prurewards'],
  },
  footer: {
    dynamicLogo: true,
    getContactDetailsFromOrg: true,
    privacyLink: 'https://www.prudential.com.sg/Privacy-Notice',
    userGuide:
      'https://cerrapoints.com/media/attachment/prurewards-user-guide.pdf',
  },
  header: {
    dynamicBg: false,
    dynamicLogo: false,
    firstLogo: true,
    secondLogo: '',
    pointDetail: false,
    notification: false,
    showDepartmentInProfile: true,
  },
  profileEdit: {
    phoneNumberDisabled: true,
    firstNameDisabled: true,
    lastNameDisabled: true,
  },
  headerLogoMaxHeight: '30px',
  hideSFStoreSFLocker: true,
  home: {
    featuredRewards: false,
    topRewards: true,
    recommendation: false,
    showRewardsOnHomePage: true,
  },
  languageAvailable: ['en'],
  languageLabel: {
    en: 'English',
  },
  links: {},
  loginReadMore: true,
  loginTermsCondition: true,
  loginPoweredByText: true,
  bottomMenu: ['home', 'rewards', 'point-history'],
  leftNavigate: ['home', 'rewards', 'point-history'],
  modules: {
    home: true,
    appreciate: false,
    feeds: false,
    rewards: true,
    myWallet: false,
    settings: true,
    pointsHistory: true,
    cart: true,
    receipts: false,
    leaderboard: false,
    flexiBenefits: true,
  },
  goToAdminDashboard: false,
  panel: {
    link: ['/profile/edit'],
    support: false,
  },
  pointDetailHeader: 'common.pointsRecord',
  rewards: {
    detailQty: 1,
    seeAll: {
      subcategory: 'drawer',
    },
    filters: {
      points: true,
      pointsCollapsible: false,
      rating: false,
      MAX_POINTS: 200000,
    },
    showDefaultTab: 'my-wallet',
  },
  settings: {
    hideMyAppreciationMessagesToggle: false,
    makeMyBirthdayPublicToggle: false,
    makeMyAnniversaryPublicToggle: false,
    makeMyProfilePublicToggle: false,
  },
  voucherStatus: [0, 2, 3, 4, 5, 9, 11],
  zhLang: 'zh-hant',
  showExpiringPoints: true,
  showYearlyExpiringPoints: false,
  isUserRewardPreferenceEnabled: true,
  sideCarousel: [
    {
      img: 'assets/img/slider/4.png',
    },
  ],
  rewardDetailBackRoute: ['/rewards/my-wallet'],
  hideRewardsTnC: true,
  showPointsOnMyWallet: false,
  pageSizes: {
    web: [30, 50, 80],
    mobile: [10, 20, 30],
  },
  merchantNRewardsLink:
    'https://cerrapoints.com/media/attachment/prurewards-rewards.pdf',
  termsOfUsePoint: [
    'Gift is defined (on its own or collectively) as Prudential Points, Discount, Digital Gift Card or Voucher, Goods & Services, Third-Party Provider Points and/or any reward issued from time to time via the <a href="https://prurewards.prudential.com.sg" tabindex="-1" target="_black">Prudential Rewards Platform</a>',
    'By logging in and accepting the Gift, each Eligible Customer is deemed to have accepted and agreed to be bound by these Terms and Conditions contained herein and any other instructions, terms and conditions that Prudential may issue from time to time.',
    'The Gift must be redeemed by the deadline stipulated in the Redemption Notification sent via the registered email address or mobile number with Prudential. If an Eligible Customer fails to claim his/her Gift within the stipulated redemption period in the Redemption Notification, his/her Gift will be forfeited, and the Eligible Customer shall not have any claim against Prudential for such forfeiture. The Gift will be deemed to be unclaimed and the Eligible Customer will be deemed to have relinquished his/her entitlement to the Gift.',
    'Eligible Customers shall be solely responsible for ensuring that they provide Prudential with a valid mobile phone number or email address for the purpose of redemption. The Eligible Customer agrees and accepts that Prudential will not be responsible for any consequences arising from his/her failure to provide PACS with a true, complete, and accurate mobile phone number or email address and/or notify Prudential of any change(s) to his/her mobile phone number and email address.',
    'Prudential reserves the right to amend, withdraw or supplement the Gift at its sole discretion at any time without notice or liability.',
    'Prudential reserves the right to disqualify or disregard any Eligible Customer who does not comply with the Terms and Conditions contained herein.',
    'Prudential is not obliged to replace any qualification letter, email or SMS expired, lost or not received.',
    'Prudential makes no representation or warranty whatsoever as to the quality or fitness for purpose or any other implied terms or conditions with respect to the Gift. Any dispute about the Gift must be resolved directly with the supplier of the Gift. Merchant terms and conditions apply',
    'Prudential is not liable for any injury, death, claim, loss or damage whatsoever arising in connection with the Gift.',
    'The Gift is not exchangeable for cash, credit or any other item in part of in whole and is not replaceable, if expired, lost or stolen. The validity period of the Gift is non-extendable. Prudential will be under no obligation to replace or pay to Eligible Customers the value of any Gift that that is not utilised by the Eligible Customers before the end of the validity period of the Gift as may be stipulated by Prudential and/or relevant merchants.',
    'Prudential assumes no responsibility for incomplete, lost, late, damaged, illegible or misdirected forms or email communication, for technical hardware or software failures of any kind, lost or unavailable network connections, or failed incomplete, garbled or delayed electronic transmission which may limit an Eligible Customer’s ability to redeem his/her Gift.',
    'Upon redemption of your gift voucher(s)/card(s) from your preferred merchant, refer to the corresponding expiry dates and terms and conditions. Redeemed gift voucher(s)/card(s) cannot be exchanged, extended or replaced. Prudential shall not be liable for any loss of, damage to, defects, delay, mis-delivery or non-delivery of the Gift',
    'Prudential may at its discretion forfeit the Gift, or, if already awarded, reclaim the Gift at the expense of the Eligible Customer without payment, compensation, or having to give any reason whatsoever in the event Prudential subsequently discovers that the Eligible Customer is not eligible to participate in the said Promotion and/or to receive the Gift',
    'Any Promotion conducted by Prudential is subject to availability of the Gifts, and Prudential may vary these terms and conditions (including the value of the Gift and offer an alternative gift as replacement if the Gift is out of stock) at any time without notice or liability.',
    'In the event of any inconsistency or discrepancy between the Terms and Conditions contained herein and the contents of any brochure, marketing and/or promotional materials relating to any Promotion conducted by Prudential, the Terms and Conditions contained herein shall prevail.',
    'Failure by Prudential to exercise any of its right or remedy under the Terms and Conditions contained herein does not constitute a waiver of that right or remedy.',
    'By using the Prudential Rewards Platform, all Eligible Customers agree and undertake to, at all times, indemnify, keep indemnified, and hold Prudential, its employees and agents harmless against all losses (including direct, indirect, incidental and/or consequential losses), damages (including general, special, and/or punitive damages), demands, injuries (other than personal injury caused by Prudential’s negligence), claims, costs, penalties, interest and fees (including all legal fees as between solicitor and client or otherwise on a full indemnity basis whether or not incurred in respect of any real, anticipated, or threatened legal proceedings), howsoever caused by, arising or resulting from, whether directly or indirectly, their participation in any Promotion conducted by Prudential, acceptance or usage of any Gift, and/or any breach or purported breach of these terms and conditions and/or any applicable law.',
    'The Terms and Conditions contained herein shall be governed by and construed in accordance with the laws of the Republic of Singapore and you agree to submit to the jurisdiction of the courts of the Republic of Singapore.',
    'A person who is not a party to any agreement governed by these Terms and Conditions shall have no right under the Contracts (Right of Third Parties) Act (Cap 53B) to enforce any of these Terms and Conditions.',
  ],
};
